.home {
    width: 100%;
    min-height: 100vh;

    color: #231f20;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homeHero img {
    height: 100vh;
}

.homeNav {
    width: calc(100% - 20%);
    height: 60px;
    
    padding: 0 10%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #000;
}

.homeNav a {
    margin-right: 30px;
    color: #fff;
    font-weight: bold;

    cursor: pointer;
}

.homeNav button {
    border: none;
    background-color: #8CBD22;
    color: #fff;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
}

.homeHero {
    width: 100%;
    min-height: 100vh;
    background-color: #eff5ff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home h1 {
    color: #8CBD22;
    font-size: 60px;
    margin-top: 100px;
    margin-bottom: 0px;
}

.home h2 {
    font-size: 29px;
    margin-top: 0px;
    margin-bottom: 50px;
}

.home h4 {
    font-size: 40px;
    margin-top: 0px;
}

#register {
    color: #fff;
    background-color: #8CBD22;
    padding: 5px 10px;
    border-radius: 5px;
}

.homeUsers {
    width: 100%;

    background-color: #fff;
    padding-bottom: 50px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homeUsers h4 {
    margin: 30px 0px;
}

.homeUsers .user {
    width: 60%;
    background-color: #eff5ff;
    padding: 20px;
    border-radius: 5px;

    display: flex;
    justify-content: space-between;
}

.homeUsers .user p {
    margin: 0px;
}

.homeProductsWrapper {
    display: flex;
    width: 80%;
    margin: 0 10%;
}

.homeProducts {
    width: 70%;

    padding-bottom: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.homeProductsTitle {
    margin: 30px 0px;
    margin-top: 30px;
}

.homeProductsContainer {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
}

.homeProducts .product {
    width: 23%;
    background-color: #fff;
    margin: 1%;
    border-radius: 5px;
    padding: 10px 0px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation: fadeIn 0.5s;
    transition: all ease-in-out 0.3s;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.homeProducts .product-small h4 {
    font-size: 14px !important;
}

.homeProducts .product-small p {
    font-size: 14px !important;
}

.homeProducts .product:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.homeCategories .category-wrapper {
    cursor: pointer;
}

.homeCategories .category-wrapper > ul {
    background-color: #fff;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.homeProducts .product img {
    width: 80%;
}

.homeProducts .product .imgWrapper {
    position: relative;
    display: flex;
    justify-content: center;
}

.homeProducts .product .altImg {
    top: 0;
    left: 0;
    right: 0;
  }
  
.homeProducts .product .mainImg {
    position: absolute;
    top: 0;
    left: 10%;
    right: 0;
    transition: all ease-in-out 0.3s;
}

.homeProducts .product .mainImg:hover {
    opacity: 0;
}
  
.homeProducts .product h4 {
    margin: 10px 0px;
    text-align: center;
    font-size: 16px;
}

.homeProducts .product p {
    width: 80%;
    margin: 10px 0px 0px 0px;
    text-align: center;
}

.homeButton {
    width: 200px;
    height: 50px;
    border: none;
    color: #fff;
    background-color: #8CBD22;
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;
    margin-top: 50px;
}

.homePagingButton {
    height: 32px;
}

.homeCategories {
    width: calc(30% - 30px);
    margin-right: 10px;
    padding: 10px 0px 20px 0px;
    border-radius: 5px;
    background-color: #fff;

    height: fit-content;

    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.homeCategories h4 {
    text-align: center;
}

.homeCategories p {
    margin-left: 20px;
    font-size: 24px;
}

ul {
    padding: 0 0 0 20px;
    margin: 0px;
    list-style-type: none;
}

.homeCategories ul:nth-child(1) {
    padding-left: 0px;
}

.homeCategories .category {
    margin-top: 10px;
    font-size: 16px;
}

.breadcrumbs {
    display: flex;
}

.crumb {
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
}

.product-productname {
    
}

.product-productfullname {
    min-height: 75px;
}

.product-productprice {
    
}

.product .product-tools {
    margin-top: 10px;
}

.product .product-tools button {
    margin-top: 0px;

    width: 35px;
    height: 35px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.home-input-group {
    display: flex;
}

.home-input-group input {
    height: 20px;
    width: 200px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #33333345;
    margin-right: 10px;
}

.home-input-group button {
    color: #fff;
    background-color: #8CBD22;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

@media only screen and (max-width: 600px) {
    .homeHero img {
        height: auto;
        width: 100vw;
    }
    
    .home h1 {
        font-size: 20px;
    }

    .home h2 {
        font-size: 50px;
    }

    .home h4 {
        font-size: 19px;
    }

    .homeProductsWrapper {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 0 5%;
    }

    .homeCategories {
        width: 100%;
        padding: 0;
    }

    .homeProducts {
        width: 100%;
    }

    .homeProducts .product {
        width: 45%;
    }
}